<template>
  <base-view>
    <!-- <view-header title="Forms" image="about.jpg">
      What form are you looking for?
    </view-header> -->
    <div class="prose prose-md md:prose-lg w-full text-center">
      <h1>Plan Resources</h1>
      <p>
        We want to make your initial setup as smooth and easy as we can. The
        information below will help you with the process. Are you ready?
      </p>
    </div>
    <view-section>
      <resource-group
        title="General"
        folder="general"
        :forms="formGroups['GEN']"
        :startOpen="true"
      />
      <resource-group
        title="Health Savings Account"
        folder="hsa"
        :forms="formGroups['HSA']"
        :startOpen="false"
      />
      <resource-group
        title="Flex Spending Account"
        folder="fsa"
        :forms="formGroups['FSA']"
        :startOpen="false"
      />
      <resource-group
        title="Limited Purpose Flex Spending Account"
        folder="lpf"
        :forms="formGroups['LPF']"
        :startOpen="false"
      />
      <resource-group
        title="Commuter Benefits"
        folder="transit"
        :forms="formGroups['TRANSIT']"
        :startOpen="false"
      />
      <resource-group
        title="Health Reimbursement Arrangement"
        folder="hra"
        :forms="formGroups['HRA']"
        :startOpen="false"
      />
      <resource-group
        title="Dependent Care Account"
        folder="dca"
        :forms="formGroups['DCA']"
        :startOpen="false"
      />
      <resource-group
        title="Lifestyle Spending Account"
        folder="lsa"
        :forms="formGroups['LSA']"
        :startOpen="false"
      />
      <resource-group
        title="COBRA"
        folder="cobra"
        :forms="formGroups['COBRA']"
        :startOpen="false"
      />
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";
import ResourceGroup from "./components/ResourceGroup.vue";

export default {
  components: {
    BaseView,
    ViewSection,
    ResourceGroup
  },
  data() {
    return {
      formGroups: {
        HSA: [
          "7 Tips for Contributing to Your HSA Infographic.png",
          "Consumer HSA Knowledge Infographic.png",
          "Employer Seeding Infographic.png",
          "Healthy HSA Investor Infographic.png",
          "HSA Flyer.pdf",
          "HSA High Yield FAQ.pdf",
          "HSA Infographic.jpeg",
          "HSA Insert.pdf",
          "HSA Invest Flyer.pdf",
          "HSA Investment Guide.pdf",
          "HSA PNC Investment info.pdf",
          "HSA Postcard.pdf",
          "HSA Poster.pdf",
          "HSA Retirement Infographic.jpeg",
          "HSA Web Banner.png",
          "Savvy HSA User Infographic.png",
          "Women and Investing Infographic.jpg",
          "Steps to Retirement Infographic.png",
          "WealthCare Saver Booklet Guide.pdf"
        ],
        FSA: [
          "7 Tips for Contributing to Your FSA Infographic.png",
          "FSA Blog Copy.pdf",
          "FSA Brochure.pdf",
          "FSA Flyer.pdf",
          "FSA Insert.pdf",
          "FSA Postcard.pdf",
          "FSA Poster.pdf",
          "FSA Rollover.pdf",
          "FSA Tips Infographic.jpeg",
          "FSA Web Banner.png"
        ],
        LPF: ["LPF Flyer.pdf", "LPF Product Card.pdf"],
        TRANSIT: [
          "TRN Brochure.pdf",
          "TRN FAQ.pdf",
          "TRN Flyer.pdf",
          "TRN Poster.pdf",
          "TRN Product card.pdf",
          "TRN Web Banner.png"
        ],
        HRA: [
          "HRA Brochure - After a Portion.pdf",
          "HRA Brochure - First Dollar.pdf",
          "HRA Brochure - Fund Rollover.pdf",
          "HRA Brochure - percent based.pdf",
          "HRA Brochure - year end grace.pdf",
          "HRA Card - client info needed.pdf",
          "HRA Flyer.pdf",
          "HRA postcard - After participant portion.pdf",
          "HRA Postcard - First Dollar.pdf",
          "HRA Postcard - Fund Rollover.pdf",
          "HRA Postcard - Percent Based.pdf",
          "HRA Poster - After participant portion.pdf",
          "HRA Poster - First dollar.pdf",
          "HRA Poster - Fund Rollover.pdf",
          "HRA Poster - Percent based.pdf",
          "HRA Web Banner.png",
          "ICHRA Flyer.pdf",
          "ICHRA - Future of Health Benefits Infographic.png"
        ],
        DCA: [
          "DCA Brochure.pdf",
          "DCA FAQ.pdf",
          "DCA Flyer.pdf",
          "DCA Insert.pdf",
          "DCA Product Card.pdf",
          "DCA Web Banner.png"
        ],
        COBRA: [
          "COBRA - Benefits During a Leave of Absence Guide.pdf",
          "COBRA Enrollment Guide.pdf",
          "COBRA Direct Billing Enrollment Guide.pdf",
          "COBRA - Employee to Cobra Participant Guide.pdf",
          "COBRA Millenial Infographic.png",
          "COBRA - Adding Active Insured Employee Guide.pdf"
        ],
        GEN: [
          "Post Pandemic Views on Health Benefits Infographic.png",
          "Contact Rocky Mountain Reserve Flyer.pdf",
          "Bypassing Benefits Infographic.png",
          "Financial Resolutions Infographic.png",
          "Inflation Infographic.png",
          "Employers Increasing Participation Video.mp4",
          "Overall Guide to Your CDH Benefits Video.mp4",
          "Women and Investing Infographic.png",
          "Am I in the Right Account Flyer.pdf",
          "Mental Health Infographic.png"
        ],
        LSA: [
          "Lifestyle Spending Account FAQ.pdf",
          "https://rmr.zendesk.com/hc/en-us/articles/13444362576147-Exploring-the-Tax-Implications-of-Lifestyle-Spending-Accounts-LSA-for-Employers"
        ]
      }
    };
  }
};
</script>

<style scoped>
.form-link:nth-last-of-type(2):nth-child(odd),
.form-link:last-of-type {
  border-bottom: 0;
}

/* .form-link:nth-of-type(4n),
  .form-link:nth-of-type(4n-1) {
    background: theme("colors.neutral.50");
  }
  
  .form-link:nth-of-type(4n):hover,
  .form-link:nth-of-type(4n-1):hover {
    background: theme("colors.neutral.100");
  } */
</style>
